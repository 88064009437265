.card {

  border: 1px solid white;
  border-radius: 8px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  



}

.header-card .card {
  padding: 0; 
}

@media (min-width: 768px) {
  
}

