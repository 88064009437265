.mobile-view{
    color: white;

    
}

.mobile-bottom{
    background: linear-gradient(180deg, #249266 0%, #00301D 100%),
linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));

}