@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
    font-style: italic;
  }

body {

    font-family: 'Roboto', sans-serif;
    background-color: #EDEEF3;

    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}


.button-primary-color{
    background-color: #00663C;
}

.text-secondary-color{
    color: #016F8C;
}

.text-primary-color{
    color: #00663C;
}
