.feedDetail-card {
    margin: 20px;

}
.card{
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}
.feedDetails-card {
    display: flex;
    justify-content: center;
    margin-top: 150px;
}

.feedCard {
    margin: 10px;
}

.feed-split {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.feed-split p {
    padding: 17px 0px 0px 27px;
}

.buttons {
    margin: 0px 30px;
}
.meter{
    margin : 5px;
}

@media only screen and (max-width: 767px) {

    .feedDetails-card {
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
    }
        .feed-split p {
            color: var(--Natural-Black, #000);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        }


    }