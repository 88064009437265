.feedBarGraph-container{
    height: 300px;
    width: 280px;
    margin: 0 2rem 4rem 2rem;
    font-size: 1rem;
}

@media (min-width: 768px) {

    .feedBarGraph-container{
        height: 250px;
        width: 440px;
        margin: 0 2rem 2rem 2rem;
   }
    
    }


    // h-30 w-60 md:h-64 md:w-120 md:mb-8 