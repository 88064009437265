.custom-button {
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #00663C;
    background-color: white;
    color: #00663C;
    padding: 16px 32px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease; 
}

@media screen and (max-width: 768px) {
    .custom-button {
        padding: 12px 24px;
        font-size: 14px;
    }
    
    .custom-button svg {
        font-size: 20px; 
        margin-right: 5px; 
    }
}
