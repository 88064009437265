.submitbtn {
    border-radius: 10px;
    background: #00663C;
    color: white;
    padding: 16px 32px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease; // Add transition for smoother size changes

    @media screen and (max-width: 768px) {
        padding: 12px 24px;
        font-size: 14px;
    }
}
