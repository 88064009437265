

.form-heading {
    font-size: 1.5rem; 
    font-weight: bold;
    margin-bottom: 0.25rem; 
    text-align: center;
  }
  
  .form-subheading {
    font-size: 0.875rem; 
    color: #4b5563; 
    margin-bottom: 2rem; 
    text-align: center;
  }

  .forgot-heading{
    font-size: 1.5rem; 
    font-weight: bold;
    margin-bottom: 0.25rem; 
    text-align: center;
  }

  @media (min-width: 768px) {
    .form-heading {
        font-size: 2rem;
        margin-bottom: 0;
        line-height: 1;
        text-align: left;
    }

    .form-subheading {
        font-size: 1rem;
        text-align: left;
        margin-bottom: 1.5rem; 
      }


}
  